<template>
  <div class="recharge-pay">
    <div class="recharge-pay-top">
      <img src="https://gw.alipayobjects.com/zos/rmsportal/HWuSTipkjJRfTWekgTUG.svg" />
      <div class="recharge-pay-bot">
        <span>支付中...</span>
        <span>请在规定时间内完成支付</span>
      </div>
    </div>
  </div>
</template>


<script>
import orderInfo from '@api/order';
import { getUrlArgObject } from '@/utils/tools';
import { wxPayTwo } from "@/utils/wxpay";
import wx from 'weixin-js-sdk';

export default {
  methods: {
    async userPay(pay_token, paysn) {
      try {
        const res = await orderInfo.createPayment(pay_token, paysn);
        if (res.code === 200) {
          wxPayTwo(res.data);
        } else {
          setTimeout(()=> wx.closeWindow(), 1000);
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  mounted() {
    try {
      const { pay_token, paysn } = getUrlArgObject();
      this.$nextTick(()=> {
        if (pay_token && paysn) {
          this.userPay(pay_token, paysn)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }
}
</script>


<style scoped>
.recharge-pay {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.recharge-pay-top {
  width: 100%;
  height: 200px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
}
.recharge-pay-bot {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  margin-top: 20px;
  padding-bottom: 20px;
}
</style>